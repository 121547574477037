
export default {
  name: 'AppMessage',
  props: {
    icon: {
      type: String,
    },
    title: {
      type: String,
    },
    message: {
      type: String,
    },
    type: {
      type: String,
      default: 'success',
      validator: () => [
        'primary',
        'secondary',
        'success',
        'danger',
        'warning',
        'info',
        'light',
        'dark',
      ],
    },
  },
};
